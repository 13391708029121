/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

// antd components
import {
    Layout,
    Menu as AntMenu,
    Typography as AntTypography,
    PageHeader,
    Button as AntButton,
    Popover,
    Tag,
    Drawer,
    Dropdown,
} from 'antd';

// antd icons
import {
    HomeOutlined,
    BulbOutlined,
    TrophyOutlined,
    DownloadOutlined,
    BookOutlined,
    StarOutlined,
    DatabaseOutlined,
    RocketOutlined,
    NotificationOutlined,
    FolderOpenOutlined,
    SettingOutlined,
    LoadingOutlined,
    UserOutlined,
    LoginOutlined,
    LogoutOutlined,
    FormOutlined,
    DollarOutlined,
    AreaChartOutlined,
    BankOutlined,
    FileAddOutlined,
    MenuOutlined,
    DownOutlined,
    AccountBookOutlined,
    ArrowLeftOutlined,
} from '@ant-design/icons';

// antd props
import { TitleProps } from 'antd/lib/typography/Title';
import { ButtonProps } from 'antd/lib/button/button';

import styled from 'styled-components';

import { useRouter } from 'next/dist/client/router';

import { setCookie } from '../../utils/browser';
import user from '../../services/user';
import { clearCachedToken, getToken } from '../../services/_config';
import WebsiteFooter from './WebsiteFooter';

const { SubMenu } = AntMenu;
const { Content, Footer, Header, Sider } = Layout;
const { Title } = AntTypography;

// const ColorPrimary = '#3f51b5';

const Menu = styled(AntMenu)`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }
`;

const PageTitle = styled(Title)<TitleProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        letter-spacing: 1.3px;
        font-weight: 700;
    }
`;

const Button = styled(AntButton)<ButtonProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        border-radius: 5px;
        margin-right: 5px;
        padding: 0px 15px;
    }
`;

const DesktopView = styled.div`
    display: none;
    @media only screen and (min-width: 600px) {
        display: initial;
    }
`;

const MobileView = styled.div`
    @media only screen and (min-width: 600px) {
        display: none;
    }
`;

const CustomTabs = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 5px 0 10px;
    border-bottom: 1px solid #999;

    a {
        &:not(:first-child) {
            margin-left: 10px;
        }
        font-size: 10px;
        border: 1px solid #999;
        border-radius: 2px;
        padding: 3px 5px;
    }
`;

type TProps = {
    children: JSX.Element | JSX.Element[];
};

type TMenuItem = {
    title: string;
    url: string | TMenuItem[];
    color?: string;
    isAdmin?: boolean;
    newSection?: boolean;
    icon?: JSX.Element;
};

const drawerWidth = 240;

const navLinksCommon: TMenuItem[] = [
    {
        title: 'SLOMF',
        url: [
            { title: 'Home', url: '/' },
            { title: 'About', url: '/slomf_about' },
            { title: 'History', url: '/slomf_history' },
            { title: 'People', url: '/slomf_people' },
            { title: 'MoU with the Ministry of Education', url: '/slomf_mou' },
            // { title: 'Science & CT Cell', url: '/slomf_science' },
        ],
        icon: <HomeOutlined />,
    },
    {
        title: 'Scholarship Fund',
        url: '/scholarship_fund',
        icon: <StarOutlined />,
    },
    {
        title: 'Competitions',
        url: '/competitions',
        icon: <BulbOutlined />,
    },
    {
        title: 'Download certificates',
        url: '/slmc-certificates',
        icon: <DownloadOutlined />,
    },
    {
        title: 'Study materials',
        url: [
            { title: 'Purchase e-Books', url: '/ebooks' },
            { title: 'Purchase v-Books (Video Books)', url: '/videos' },
        ],
        icon: <BookOutlined />,
    },
    {
        title: 'Results',
        url: [
            { title: 'AMO', url: '/results/amo' },
            { title: 'SASMO', url: '/results/sasmo' },
            { title: 'SINGA ASSESSMENT', url: '/results/singa_assessment' },
            { title: 'SLMO', url: '/results/slmo' },
            { title: 'APMO', url: 'http://www.apmo-official.org/country_report/LKA/all' },
            { title: 'IMC', url: '/results/imc' },
            { title: 'IMO', url: '/results/imo' },
            { title: 'HKISO Results', url: '/results/hkiso' },
            { title: 'HKIMO Results', url: '/results/hkimo' },
            { title: 'HKICO Results', url: '/results/hkico' },
            { title: 'TIMO Results', url: '/results/timo' },
            { title: 'WIMO Results', url: 'http://www.worldimo.org/wimo-final-2018-result.html' },
            {
                title: 'Cyberspace Mathematical Competition 2020',
                url: '/pdf/results/PARTICIPANTS-CYBERSPACE-MC-2020.pdf',
            },
        ],
        icon: <StarOutlined />,
    },
    {
        title: 'Resources',
        url: [
            { title: 'SLMO', url: '/resources/slmo' },
            { title: 'TIMO', url: '/resources/timo' },
            { title: 'HKIMO', url: '/resources/hkimo' },
            { title: 'HKISO', url: '/resources/hkiso' },
            { title: 'WIMO', url: '/resources/wimo' },
        ],
        icon: <DatabaseOutlined />,
    },
    {
        title: 'Weekly Quiz',
        url: [
            { title: 'Challenging Maths', url: '/weekly_quiz/challenging_maths/about' },
            { title: "Chanakya's Mind Games", url: '/weekly_quiz/mind_games/about' },
        ],
        icon: <RocketOutlined />,
    },
    {
        title: 'News',
        url: [
            { title: 'Posts', url: '/news' },
            { title: 'Mathematics', url: 'https://phys.org/science-news/mathematics/' },
            { title: 'Competitions', url: '/pdf/Info-on-HKIMO-2018-n-2019-edited.pdf' },
        ],
        icon: <NotificationOutlined />,
    },
    {
        title: 'Gallery',
        url: '/gallery',
        icon: <FolderOpenOutlined />,
    },
];

const navLinksCommonAdmin: TMenuItem[] = [
    {
        title: 'SLOMF',
        url: [
            { title: 'Home', url: '/' },
            { title: 'About', url: '/slomf_about' },
            { title: 'History', url: '/slomf_history' },
            { title: 'People', url: '/slomf_people' },
            { title: 'MoU with the Ministry of Education', url: '/slomf_mou' },
            // { title: 'Science & CT Cell', url: '/slomf_science' },
        ],
        icon: <HomeOutlined />,
    },
    {
        title: 'Competitions',
        url: [
            { title: 'Registrations', url: '/competitions/registrations' },
            { title: 'Regulations', url: '/competitions/regulations', newSection: true },
            { title: 'Important Dates', url: '/competitions/dates' },
            { title: 'HKIMO', url: '/competitions/hkimo', newSection: true },
            { title: 'SLMO & SLJMO 2024', url: '/competitions/slmo_sljmo_2024' },
            // { title: 'SLMC 2023 Online Training Camp', url: '/competitions/slmc2023training' },
            { title: 'APMO', url: '/competitions/apmo' },
            { title: 'IMC', url: '/competitions/imc' },
            { title: 'ITMO', url: '/competitions/itmo' },
            { title: 'IMO', url: 'https://www.imo-official.org/' },
            { title: 'TIMO', url: 'https://www.thaiimo.com/' },
            { title: 'WIMO', url: 'http://www.worldimo.org/' },
            { title: 'HKISO', url: '/competitions/hkiso' },
        ],
        icon: <BulbOutlined />,
    },
    {
        title: 'Scholarship Fund',
        url: '/scholarship_fund',
        icon: <StarOutlined />,
    },
    {
        title: 'Compete',
        url: '/compete',
        icon: <TrophyOutlined />,
    },
    {
        title: 'Download certificates',
        url: '/slmc-certificates',
        icon: <DownloadOutlined />,
    },
    {
        title: 'Study materials',
        url: [
            { title: 'Purchase e-Books', url: '/ebooks' },
            { title: 'Purchase v-Books (Video Books)', url: '/videos' },
        ],
        icon: <BookOutlined />,
    },
    {
        title: 'Results',
        url: [
            { title: 'AMO', url: '/results/amo' },
            { title: 'VANDA', url: '/results/vanda' },
            { title: 'DrCT', url: '/results/drct' },
            { title: 'SASMO', url: '/results/sasmo' },
            { title: 'SINGA ASSESSMENT', url: '/results/singa_assessment' },
            { title: 'SLMO', url: '/results/slmo' },
            { title: 'APMO', url: 'http://www.apmo-official.org/country_report/LKA/all' },
            { title: 'IMC', url: '/results/imc' },
            { title: 'IMO', url: '/results/imo' },
            { title: 'HKISO Results', url: '/results/hkiso' },
            { title: 'HKIMO Results', url: '/results/hkimo' },
            { title: 'HKICO Results', url: '/results/hkico' },
            { title: 'TIMO Results', url: '/results/timo' },
            { title: 'WIMO Results', url: 'http://www.worldimo.org/wimo-final-2018-result.html' },
            {
                title: 'Cyberspace Mathematical Competition 2020',
                url: '/pdf/results/PARTICIPANTS-CYBERSPACE-MC-2020.pdf',
            },
        ],
        icon: <StarOutlined />,
    },
    {
        title: 'Resources',
        url: [
            { title: 'SLMO', url: '/resources/slmo' },
            { title: 'TIMO', url: '/resources/timo' },
            { title: 'HKIMO', url: '/resources/hkimo' },
            { title: 'HKISO', url: '/resources/hkiso' },
            { title: 'WIMO', url: '/resources/wimo' },
        ],
        icon: <DatabaseOutlined />,
    },
    {
        title: 'Weekly Quiz',
        url: [
            { title: 'Challanging Maths', url: '/weekly_quiz/challenging_maths/about' },
            { title: "Chanakya's Mind Games", url: '/weekly_quiz/mind_games/about' },
        ],
        icon: <RocketOutlined />,
    },
    {
        title: 'News',
        url: [
            { title: 'Posts', url: '/news' },
            { title: 'Mathematics', url: 'https://phys.org/science-news/mathematics/' },
            { title: 'Competitions', url: '/pdf/Info-on-HKIMO-2018-n-2019-edited.pdf' },
        ],
        icon: <NotificationOutlined />,
    },
    {
        title: 'Gallery',
        url: '/gallery',
        icon: <FolderOpenOutlined />,
    },
    {
        title: 'Admin',
        url: '/admin',
        color: '#f00',
        isAdmin: true,
        icon: <SettingOutlined />,
    },
];

const navLinksCompetePublic: TMenuItem[] = [
    {
        icon: <HomeOutlined />,
        title: 'Home',
        url: '/compete',
    },
    {
        icon: <FileAddOutlined />,
        title: '[1] Application',
        url: '/compete/applications',
    },
    {
        icon: <DollarOutlined />,
        title: '[2] Payment',
        url: '/compete/payments',
    },
];

const navLinksCompeteAdmin: TMenuItem[] = [
    {
        icon: <HomeOutlined />,
        title: 'Compete Home',
        url: '/compete',
    },
    {
        icon: <AreaChartOutlined />,
        title: 'Visual Overview',
        url: '/compete/index2',
    },
    {
        icon: <UserOutlined />,
        title: 'Students',
        url: '/compete/register',
    },
    {
        icon: <FormOutlined />,
        title: 'Applications',
        url: '/compete/applications',
    },
    {
        icon: <DollarOutlined />,
        title: 'Payments',
        url: '/compete/payments',
    },
    {
        icon: <BulbOutlined />,
        title: 'Competitions',
        url: '/compete/competitions',
    },
    {
        icon: <AccountBookOutlined />,
        title: 'Competition Reports',
        url: '/compete/reports',
    },
    {
        icon: <BankOutlined />,
        title: 'Schools',
        url: '/compete/schools',
    },
];

const pageTitle = 'Sri Lanka Olympiad Mathematics Foundation';

const Website = ({ children }: TProps): JSX.Element => {
    const { push } = useRouter();

    const router = useRouter();
    const [navDrawerOpen, setNavDrawerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [userType, setUserType] = useState(0);
    const [userDetail, setUserDetail] = useState({ name: '', phone: '' });

    const { pathname } = router;
    const isCompete = /^\/compete/.test(pathname);
    let navLinks: TMenuItem[] = navLinksCommon;
    if (userType && userType === 2) {
        navLinks = navLinksCommonAdmin;
    }

    if (isCompete) {
        navLinks = [];
        if (userType) {
            if (userType === 2) {
                navLinks = navLinksCompeteAdmin;
            } else {
                navLinks = navLinksCompetePublic;
            }
        }
    }

    useEffect(() => {
        (async (): Promise<void> => {
            try {
                const { data: profileData } = await user.get();
                const { name, phone, type } = profileData[0];

                if (type) {
                    setUserType(type);
                }
                setIsAuthorized(true);
                setUserDetail({ name, phone });
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                // -- Silently fail
            }
        })();
    }, []);

    const handleDrawerToggle = (): void => {
        setNavDrawerOpen(prevState => !prevState);
    };

    const handleSignin = (): void => {
        router.push('/login');
    };

    const handleSignout = (): void => {
        setCookie('token', null);
        clearCachedToken();
        setTimeout(async () => {
            let token = await getToken();
            while (token) {
                // eslint-disable-next-line no-await-in-loop
                token = await getToken();
            }
            window.location.href = '/';
        }, 500);
    };

    const Logo = ({ color, small }: { color: 'white' | 'black' | 'color'; small?: boolean }): JSX.Element => (
        <Button
            style={{ background: 'none', border: 'none', boxShadow: 'none', padding: 0, margin: 0, height: 50 }}
            onMouseDown={(): void => {
                push('/');
            }}
            onKeyDown={(): void => {
                push('/');
            }}>
            <img
                src={`/images/logo-${color}.svg`}
                height={small ? 40 : 50}
                alt="SLMOF"
                style={{
                    padding: 5,
                    marginTop: -4,
                    marginBottom: -4,
                }}
            />
        </Button>
    );

    return (
        <>
            {/* Desktop View (1280px and up) */}
            <DesktopView style={{ backgroundColor: '#fff' }}>
                <Layout style={{ height: isCompete ? 'auto' : '100%', backgroundColor: '#fff' }}>
                    <Header
                        style={{
                            background: '#fff',
                            display: 'flex',
                            flex: '1 1 auto',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            alignContent: 'center',
                            position: 'fixed',
                            zIndex: 2,
                            width: '100%',
                            boxShadow: '0 2px 8px #f0f1f2',
                            fontFamily:
                                ' Avenir,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
                            padding: '0px 31.1px',
                        }}>
                        <div style={{ paddingLeft: 31 }}>
                            <Logo color="color" />
                            <Button
                                onMouseDown={(): void => {
                                    router.push('/');
                                }}
                                style={{ height: 40 }}
                                type="text">
                                <PageTitle
                                    level={4}
                                    style={{
                                        margin: 0,
                                        maxWidth: 400,
                                        fontSize: 14,
                                        fontWeight: 500,
                                        whiteSpace: 'normal',
                                        textAlign: 'left',
                                    }}>
                                    {pageTitle}
                                </PageTitle>
                            </Button>
                        </div>

                        <PageHeader
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                            extra={
                                isLoading ? (
                                    <LoadingOutlined key="loading" />
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        {!router?.pathname.includes('compete') && (
                                            <Button
                                                type="primary"
                                                style={{
                                                    marginTop: 0,
                                                }}
                                                onMouseDown={(): void => {
                                                    push('/compete');
                                                }}>
                                                Apply for competitions here
                                            </Button>
                                        )}
                                        {isAuthorized ? (
                                            <>
                                                <Dropdown
                                                    overlay={
                                                        <Menu>
                                                            <Menu.Item>
                                                                <p>
                                                                    {userType === 1
                                                                        ? ''
                                                                        : userType === 2
                                                                        ? 'Admin'
                                                                        : ''}
                                                                </p>
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                <p>{userDetail.phone}</p>
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                <Button
                                                                    style={{ width: '100%' }}
                                                                    onMouseDown={handleSignout}>
                                                                    Sign Out
                                                                </Button>
                                                            </Menu.Item>
                                                        </Menu>
                                                    }>
                                                    <Button type="text">
                                                        {userDetail.name} <DownOutlined />
                                                    </Button>
                                                </Dropdown>
                                            </>
                                        ) : (
                                            <div style={{ textAlign: 'center' }}>
                                                <Button
                                                    icon={<UserOutlined />}
                                                    style={{
                                                        marginTop: 0,
                                                    }}
                                                    onMouseDown={handleSignin}>
                                                    Sign In
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                )
                            }
                        />
                    </Header>

                    <Layout style={{ height: '100%', background: '#fff' }}>
                        {isCompete && !isAuthorized ? null : (
                            <Sider
                                theme="light"
                                width={drawerWidth}
                                style={{
                                    maxHeight: '98vh',
                                    position: 'fixed',
                                    left: 0,
                                    top: 0,
                                    marginBottom: 64,
                                    marginTop: 64,
                                    zIndex: isCompete ? 0 : 1,
                                }}>
                                <Menu mode="inline" defaultOpenKeys={['SLOMF']} style={{ marginBottom: 50 }}>
                                    {isCompete && (
                                        <Menu.Item className="customclass" icon={<ArrowLeftOutlined />}>
                                            <a href="/" style={{ color: '#1890ff' }}>
                                                Back
                                            </a>
                                        </Menu.Item>
                                    )}
                                    {navLinks.map((navLink, indexNavLink) => {
                                        if (typeof navLink.url === 'string') {
                                            const isCurrent = router.pathname === navLink.url;
                                            return (
                                                <Menu.Item
                                                    key={`${navLink.url}-${indexNavLink + 1}`}
                                                    className="customclass"
                                                    icon={navLink.icon}
                                                    // onMouseDown={handleRoute(navLink.url)}
                                                >
                                                    <a
                                                        href={navLink.url}
                                                        style={isCurrent ? { fontWeight: 'bold' } : {}}>
                                                        {navLink.title}
                                                    </a>
                                                </Menu.Item>
                                            );
                                        }
                                        if (Array.isArray(navLink.url) && navLink.url.length > 0) {
                                            return (
                                                <SubMenu key={navLink.title} icon={navLink.icon} title={navLink.title}>
                                                    {navLink.url.map((navLinkNestedUrl, indexNavlinkURL) => {
                                                        if (typeof navLinkNestedUrl.url === 'string') {
                                                            const isCurrent = router.pathname === navLinkNestedUrl.url;

                                                            return (
                                                                <Menu.Item
                                                                    // eslint-disable-next-line prettier/prettier
                                                                        key={`${navLinkNestedUrl.title}-${indexNavlinkURL + 1}`}
                                                                    icon={navLinkNestedUrl.icon}
                                                                    // onMouseDown={handleRoute(navLinkNestedUrl.url)}
                                                                >
                                                                    <a
                                                                        href={navLinkNestedUrl.url}
                                                                        style={isCurrent ? { fontWeight: 'bold' } : {}}>
                                                                        {navLinkNestedUrl.title}
                                                                    </a>
                                                                </Menu.Item>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </SubMenu>
                                            );
                                        }
                                        return <div key={`${indexNavLink + 1}`} />;
                                    })}
                                </Menu>
                            </Sider>
                        )}

                        <Content
                            style={{
                                marginTop: 64,
                                marginBottom: 64,
                                marginLeft: isCompete && !isAuthorized ? 0 : drawerWidth,
                                alignItems: 'center',
                                minHeight: '100vh',
                            }}>
                            <div style={{ margin: '2% 5%', boxSizing: 'border-box' }}>{children}</div>
                        </Content>
                    </Layout>
                    <Footer
                        style={{
                            padding: 0,
                            marginLeft: !isCompete ? drawerWidth : 'auto',
                            position: 'static',
                            bottom: '0',
                            width: isCompete ? '100%' : 'auto',
                        }}>
                        <WebsiteFooter minimal={isCompete} />
                    </Footer>
                </Layout>
            </DesktopView>

            {/* Mobile View (360px and up) */}
            <MobileView>
                <Layout style={{ height: '100%', background: '#fff' }}>
                    <Header
                        style={{
                            background: '#fff',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0px 10px',
                            position: 'fixed',
                            zIndex: 2,
                            width: '100%',
                            boxShadow: '0 2px 8px #f0f1f2',
                            fontFamily:
                                ' Avenir,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
                        }}>
                        {!isCompete || userType === 2 ? (
                            <MenuOutlined onClick={handleDrawerToggle} style={{ fontSize: '18px' }} />
                        ) : null}
                        <Button
                            style={{
                                lineHeight: 'initial',
                                padding: '0px 10px',
                                fontSize: '13px',
                                fontWeight: 500,
                                textAlign: 'center',
                                fontFamily:
                                    ' Avenir,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
                                background: 'none',
                                border: 'none',
                                boxShadow: 'none',
                            }}
                            onClick={(): void => {
                                push('/');
                            }}>
                            Sri Lanka Olympiad Mathematics Foundation
                        </Button>
                        <Logo color="color" small />
                    </Header>

                    <Drawer
                        title={pageTitle}
                        placement="left"
                        closable
                        onClose={handleDrawerToggle}
                        visible={navDrawerOpen}
                        bodyStyle={{ padding: '0px' }}>
                        <Menu mode="inline" defaultOpenKeys={['SLOMF']}>
                            {isAuthorized ? (
                                <Menu.Item icon={<UserOutlined />} disabled>
                                    <Popover content={userDetail.phone} placement="bottom">
                                        <Tag
                                            style={{
                                                borderRadius: '20px',
                                                cursor: 'pointer',
                                            }}>
                                            {`${userDetail.name} ${
                                                userType === 1 ? '' : userType === 2 ? 'Admin' : ''
                                            }`}
                                        </Tag>
                                    </Popover>
                                </Menu.Item>
                            ) : null}
                            <Menu.Item
                                style={{ color: '#1890ff' }}
                                icon={isAuthorized ? <LogoutOutlined /> : <LoginOutlined />}
                                onMouseDown={isAuthorized ? handleSignout : handleSignin}>
                                {isAuthorized ? 'Sign Out' : 'Sign In'}
                            </Menu.Item>
                            {navLinks.map((navLink, indexNavLink) => {
                                if (typeof navLink.url === 'string') {
                                    const isCurrent = router.pathname === navLink.url;

                                    return (
                                        <Menu.Item
                                            key={`${navLink.url}-${indexNavLink + 1}`}
                                            className="customclass"
                                            icon={navLink.icon}
                                            // onMouseDown={handleRoute(navLink.url)}
                                        >
                                            <a href={navLink.url} style={isCurrent ? { fontWeight: 'bold' } : {}}>
                                                {navLink.title}
                                            </a>
                                        </Menu.Item>
                                    );
                                }
                                if (Array.isArray(navLink.url) && navLink.url.length > 0) {
                                    return (
                                        <SubMenu key={navLink.title} icon={navLink.icon} title={navLink.title}>
                                            {navLink.url.map((navLinkNestedUrl, indexNavLinkURL) => {
                                                if (typeof navLinkNestedUrl.url === 'string') {
                                                    const isCurrent = router.pathname === navLinkNestedUrl.url;

                                                    return (
                                                        <Menu.Item
                                                            key={`${navLinkNestedUrl.title}-${indexNavLinkURL + 1}`}
                                                            icon={navLinkNestedUrl.icon}
                                                            // onMouseDown={handleRoute(navLinkNestedUrl.url)}
                                                        >
                                                            <a
                                                                href={navLinkNestedUrl.url}
                                                                style={isCurrent ? { fontWeight: 'bold' } : {}}>
                                                                {navLinkNestedUrl.title}
                                                            </a>
                                                        </Menu.Item>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </SubMenu>
                                    );
                                }
                                return <div key={`${indexNavLink + 1}`} />;
                            })}
                        </Menu>
                    </Drawer>

                    <Content
                        style={{
                            marginTop: 64,
                            marginLeft: isCompete && !isAuthorized ? 0 : 'auto',
                            width: '100%',
                            height: isCompete ? '100%' : 'auto',
                            overflow: 'auto',
                        }}>
                        <div style={{ margin: '5px 5%', height: '100%', marginBottom: '125px' }}>
                            {isCompete && (
                                <CustomTabs>
                                    <a href="/compete">Home</a>
                                    <a href="/compete/applications">[1] Application</a>
                                    <a href="/compete/payments">[2] Payment</a>
                                </CustomTabs>
                            )}
                            {children}
                        </div>
                    </Content>

                    <Footer
                        style={{
                            padding: 0,
                            marginLeft: 'auto',
                            width: '100%',
                            bottom: 0,
                        }}>
                        <WebsiteFooter minimal={isCompete} />
                    </Footer>
                </Layout>
            </MobileView>
        </>
    );
};

export default Website;
